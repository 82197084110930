/* eslint-disable no-undef */

import * as Sentry from "@sentry/vue"

import "./bootstrap"
import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/vue3"
import "babel-polyfill"
import * as globals from "./helpers/globals"
import { createPinia } from "pinia"
import { zRoute } from "./helpers/route-helpers"

import {
  // Directives
  VTooltip,
  // Components
  Tooltip,
} from "floating-vue"
import "floating-vue/dist/style.css"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"

import i18n from "./i18n"
import store from "./store"
import initializeUserOnboarding from "./onboard-user"
import { setThemeStyles } from "./helpers/setThemeStyles"

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Kadonation Select"

createInertiaApp({
  title: title => `${title} | ${appName}`,
  resolve: name => {
    return resolvePageComponent(
      `./Inertia/Pages/${name}.vue`,
      import.meta.glob("./Inertia/Pages/**/*.vue"),
    )
  },
  progress: {
    color: "var(--color-brand-primary)",
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    // Sentry silences the errors (prevents them to be logged to the console) by default so make
    // sure that the DSN is correct or leave it empty in `.env` file.
    // Remember that both `app.js` and `app-inertia.js` is included in the app until the migration
    // to inertia is completed. So make sure that sentry setup is identical in both files.
    if (import.meta.env.VITE_SENTRY_VUE_DSN) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
        environment: import.meta.env.VITE_SENTRY_VUE_ENV,
        tracesSampleRate: 0.0,
        denyUrls: [/js-agent\.newrelic\.com/i, /^chrome-extension:\/\//i],
      })
    }

    const setupGlobals = obj => {
      for (const key in obj) {
        app.config.globalProperties[key] = obj[key]
      }
    }
    setupGlobals(globals)
    app.config.globalProperties.zRoute = zRoute

    const pinia = createPinia()
    const user = props.initialPage?.props?.auth?.user
    const tenant = props.initialPage?.props?.tenant
    const theme = props.initialPage?.props?.theme
    const themeEnabled = theme?.enabled
    const subscription = props.initialPage?.props?.tenant_subscription?.tier?.name

    initializeUserOnboarding({
      user, tenant, subscription, themeEnabled
    })
    setThemeStyles(theme)

    app.directive("tooltip", VTooltip)
    app.component("VTooltip", Tooltip)
    app.use(plugin).use(store).use(pinia).use(i18n)

    app.mount(el)
  },
})
